import React from "react";

function LoadingBar({ progress }) {
    return (
        <div
            style={{
                position: "fixed",
                top: "0", // Place at the very top of the viewport
                left: 0,
                width: "100%",
                height: "4px", // Slightly thinner for a cleaner look
                backgroundColor: "rgba(0, 100, 0, 0.15)", // Subtle dark green track
                zIndex: 9999, // Ensure it stays above everything
                overflow: "hidden", // Ensures animation stays within bounds
            }}
        >
            <div
                style={{
                    width: `${progress}%`,
                    height: "100%",
                    backgroundColor: "red", // Solid dark green color
                    boxShadow: "0 0 15px rgba(0, 128, 0, 0.7)", // Subtle glow effect
                    position: "relative",
                    transition: "width 0.4s ease-in-out", // Smooth progress transition
                }}
            ></div>
        </div>
    );
}

export default LoadingBar;