import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";
import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useState, useCallback } from "react";
import "./styles/Testimonials.css"; // Import your custom CSS file

const AnimatedTestimonials = ({
                                  testimonials,
                                  autoplay = true, // Default autoplay enabled
                              }) => {
    const [active, setActive] = useState(0);

    // Function to handle the next testimonial
    const handleNext = useCallback(() => {
        setActive((prev) => (prev + 1) % testimonials.length); // Cycle through testimonials
    }, [testimonials.length]);

    // Function to handle the previous testimonial
    const handlePrev = useCallback(() => {
        setActive((prev) => (prev - 1 + testimonials.length) % testimonials.length); // Handle cycling backward
    }, [testimonials.length]);

    // Auto-play functionality
    useEffect(() => {
        if (autoplay) {
            const interval = setInterval(handleNext, 3000); // Set interval to 3 seconds
            return () => clearInterval(interval); // Clean up the interval on component unmount or when autoplay changes
        }
    }, [autoplay, handleNext]);

    const isActive = (index) => {
        return index === active;
    };

    const randomRotateY = () => {
        return Math.floor(Math.random() * 21) - 10;
    };

    if (!testimonials || testimonials.length === 0) {
        return <div>No testimonials available.</div>;
    }

    return (
        <div className="testimonial-container">
            <div className="testimonial-content">
                {/* Image Section */}
                <div className="testimonial-image-container">
                    <AnimatePresence>
                        {testimonials.map((testimonial, index) => (
                            <motion.div
                                key={testimonial.src}
                                initial={{
                                    opacity: 0,
                                    scale: 0.9,
                                    z: -100,
                                    rotate: randomRotateY(),
                                }}
                                animate={{
                                    opacity: isActive(index) ? 1 : 0.7,
                                    scale: isActive(index) ? 1 : 0.95,
                                    z: isActive(index) ? 0 : -100,
                                    rotate: isActive(index) ? 0 : randomRotateY(),
                                    zIndex: isActive(index)
                                        ? 1
                                        : testimonials.length - index - 3,
                                    y: isActive(index) ? [0, -80, 0] : 0,
                                }}
                                exit={{
                                    opacity: 0,
                                    scale: 0.9,
                                    z: 100,
                                    rotate: randomRotateY(),
                                }}
                                transition={{
                                    duration: 0.4,
                                    ease: "easeInOut",
                                }}
                                className="testimonial-image"
                            >
                                <img
                                    src={testimonial.src}
                                    alt={testimonial.name}
                                    width={600}
                                    height={600}
                                    draggable={false}
                                    className="testimonial-img"
                                />
                            </motion.div>
                        ))}
                    </AnimatePresence>
                </div>

                {/* Content Section */}
                <div className="testimonial-text">
                    <motion.div
                        key={active}
                        initial={{
                            y: 20,
                            opacity: 0,
                        }}
                        animate={{
                            y: 0,
                            opacity: 1,
                        }}
                        exit={{
                            y: -20,
                            opacity: 0,
                        }}
                        transition={{
                            duration: 0.2,
                            ease: "easeInOut",
                        }}
                    >
                        <h3 className="testimonial-name">{testimonials[active].name}</h3>
                        <p className="testimonial-designation">
                            {testimonials[active].designation}
                        </p>
                        <motion.p className="testimonial-description">
                            {testimonials[active].description}
                        </motion.p>
                        <motion.p className="testimonial-quote">
                            {(testimonials[active].quote || "").split(" ").map((word, index) => (
                                <motion.span
                                    key={index}
                                    initial={{
                                        filter: "blur(10px)",
                                        opacity: 0,
                                        y: 5,
                                    }}
                                    animate={{
                                        filter: "blur(0px)",
                                        opacity: 1,
                                        y: 0,
                                    }}
                                    transition={{
                                        duration: 0.2,
                                        ease: "easeInOut",
                                        delay: 0.02 * index,
                                    }}
                                    className="quote-word"
                                >
                                    {word}&nbsp;
                                </motion.span>
                            ))}
                        </motion.p>
                    </motion.div>

                    {/* Navigation Buttons */}
                    <div className="testimonial-nav-buttons">
                        <button
                            onClick={handlePrev}
                            className="nav-button"
                        >
                            <IconArrowLeft className="nav-icon" />
                        </button>
                        <button
                            onClick={handleNext}
                            className="nav-button"
                        >
                            <IconArrowRight className="nav-icon" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AnimatedTestimonials;
