import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import TitleFragment from "./TitleFragment";
import ScrollToTop from "./ScrollToTop";
import TeamMembersData from "./data/TeamMembersData";

import linkedin from "./images/team/linkedin.webp";
import linkedinHover from "./images/team/linkedin-hover.webp";
import teamPic from "./images/team/PthinkS_Team_Pic.webp";

import "../App.css";
import "./styles/Team.css";
import LoadingBar from "./ProgressBarComponent";

function Team() {
    const [hovered, setHovered] = useState(null); // Tracks which member is hovered


    return (
        <div className="team">
           


            {/* Team Picture */}
            <div>
                <img
                    className="team_img"
                    src={teamPic}
                    alt="PthinkS Team"
                />
            </div>

            {/* Company Introduction */}
            <div className="app_paragraph">
                Become part of our innovative team at PthinkS, where we specialize in
                C++, Qt, and QML software solutions. Join us in creating cutting-edge
                technologies with a commitment to excellence, ethics, and
                collaboration.
            </div>

            {/* Team Members */}
            <div className="team_members">
                {TeamMembersData.map((member, index) => (
                    <div key={index} className="team_members_individual">
                        {/* Member Image & Social Link */}
                        <div className="team_members_individual_img_div">
                            <img
                                className="team_members_individual_img"
                                src={member.src}
                                alt={`Profile picture of ${member.name}`}
                            />
                            <a
                                href={member.social}
                                target="_blank"
                                rel="noreferrer" // Secure external links
                            >
                                <img
                                    className="team_members_individual_logo"
                                    src={hovered === index ? linkedinHover : linkedin}
                                    alt={`${member.name}'s Linkedin`}
                                    onMouseEnter={() => setHovered(index)}
                                    onMouseLeave={() => setHovered(null)}
                                />
                            </a>
                        </div>
                        {/* Member Details */}
                        <div className="team_members_individual_name">{member.name}</div>
                        <div className="team_members_individual_designation">
                            {member.designation}
                        </div>
                    </div>
                ))}
            </div>

            {/* Open Positions Button */}
            <div className="team_openings">
                <Link
                    className="team_openings_button"
                    onClick={ScrollToTop}
                    to="/careers"
                >
                    View open positions
                </Link>
            </div>
        </div>
    );
}

export default Team;