import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

// import TopInfo from "./TopInfo";
import ScrollToTop from "./ScrollToTop";
import HeaderDropdownData from "./data/HeaderDropdownData";
import LoadingBar from "./ProgressBarComponent";

import logo from "./images/header/pthinks-logo.webp";
import menuIcon from "./images/header/menu.webp";
import user from "./images/header/user.webp";

import "./styles/Header.css";

function Header() {
    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(0); // Progress state
    const [isLoading, setIsLoading] = useState(false); // Loading visibility state

    const location = useLocation();
    const navigate = useNavigate();

    // Function to toggle the mobile menu
    const toggleMobileMenu = () => {
        setMobileMenuVisible(!mobileMenuVisible);
    };

    // Scroll to a specific section of the page
    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    // Handle the navigation to the "Customers" section
    const handleCustomersClick = () => {
        if (location.pathname === "/") {
            scrollToSection("landing_customers");
        } else {
            simulateLoading(() => navigate("/#landing_customers"));
        }
    };

    // Handle navigation for other routes
    const handleClick = (path) => {
        simulateLoading(() => {
            ScrollToTop();
            navigate(path);
        });
    };

    // Simulate the loading bar functionality
    // Simulate the loading bar functionality
    // Simulate the loading bar functionality
    const simulateLoading = (callback) => {
        // Reset progress and show the loading bar
        setIsLoading(true);
        setLoadingProgress(0);

        const interval = setInterval(() => {
            setLoadingProgress((prevProgress) => {
                if (prevProgress >= 100) {
                    clearInterval(interval);
                    setIsLoading(false); // Hide the loading bar
                    if (callback) callback(); // Trigger callback for navigation or other actions
                    return 100;
                }
                return prevProgress + 10; // Increment progress smoothly
            });
        }, 100); // Update progress every 100ms

        // Ensure the loading bar hides after 3 seconds (3000ms)
        setTimeout(() => {
            setIsLoading(false);
        }, 3000);
    };

    return (
        <>
            {/* Conditionally render the LoadingBar */}
            {isLoading && <LoadingBar progress={loadingProgress} />}

            <header className="header">
                {/*<TopInfo />*/}

                <div className="header_main">
                    {/* Logo */}
                    <Link
                        onClick={() => handleClick("/")}
                        className="header_logo_div"
                        to="/"
                    >
                        <img
                            src={logo}
                            className="header_logo"
                            alt="PthinkS Logo"
                        />
                    </Link>

                    {/* Navigation Items */}
                    <div className="header_components">
                        <div className="header_content_item">
                            Services{" "}
                            <span className="header_content_item_arrow">
                                &#x2B9F;
                            </span>
                            <div className="header_dropdown">
                                {HeaderDropdownData.Services.map(
                                    (item, index) => (
                                        <a
                                            key={index}
                                            className="header_dropdown_content_item"
                                        >
                                            {item}
                                        </a>
                                    )
                                )}
                            </div>
                        </div>
                        <div className="header_content_item">
                            Training{" "}
                            <span className="header_content_item_arrow">
                                &#x2B9F;
                            </span>
                            <div className="header_dropdown">
                                {HeaderDropdownData.Training.map(
                                    (item, index) => (
                                        <a
                                            key={index}
                                            className="header_dropdown_content_item"
                                        >
                                            {item}
                                        </a>
                                    )
                                )}
                            </div>
                        </div>
                        <div
                            className="header_content_item"
                            onClick={handleCustomersClick}
                        >
                            Customers
                        </div>
                        <Link
                            onClick={() => handleClick("/about")}
                            className="header_content_item"
                            to="/about"
                        >
                            About
                        </Link>
                        <Link
                            onClick={() => handleClick("/team")}
                            className="header_content_item"
                            to="/team"
                        >
                            Team
                        </Link>
                        <Link
                            onClick={() => handleClick("/reach-us")}
                            className="header_content_item"
                            to="/reach-us"
                        >
                            Reach us
                        </Link>
                        <Link
                            onClick={() => handleClick("/careers")}
                            className="header_content_item"
                            to="/careers"
                        >
                            Careers
                        </Link>
                    </div>

                    {/* Login Button */}
                    <a
                        href="http://pthinks.com:2095/"
                        target="_blank"
                        rel="noreferrer"
                        className="header_content_item_btn"
                    >
                        <img
                            className="header_content_item_btn_img"
                            src={user}
                            alt="User Login"
                        />
                        Login
                    </a>

                    {/* Mobile Menu Toggle */}
                    <div className="header_toggle" onClick={toggleMobileMenu}>
                        <img
                            className="header_toggle_icon"
                            src={menuIcon}
                            alt="Menu Icon"
                        />
                    </div>
                </div>

                {/* Mobile Menu */}
                {mobileMenuVisible && (
                    <div className="header_toggle_menu">
                        <div className="header_content_item">
                            Services
                            <div className="header_dropdown">
                                {HeaderDropdownData.Services.map(
                                    (item, index) => (
                                        <a
                                            key={index}
                                            className="header_dropdown_content_item"
                                        >
                                            {item}
                                        </a>
                                    )
                                )}
                            </div>
                        </div>
                        <div className="header_content_item">
                            Training
                            <div className="header_dropdown">
                                {HeaderDropdownData.Training.map(
                                    (item, index) => (
                                        <a
                                            key={index}
                                            onClick={toggleMobileMenu}
                                            className="header_dropdown_content_item"
                                        >
                                            {item}
                                        </a>
                                    )
                                )}
                            </div>
                        </div>
                        <Link
                            onClick={() => handleClick("/")}
                            className="header_content_item"
                        >
                            Customers
                        </Link>
                        <Link
                            onClick={() => handleClick("/about")}
                            className="header_content_item"
                        >
                            About
                        </Link>
                        <Link
                            onClick={() => handleClick("/team")}
                            className="header_content_item"
                        >
                            Team
                        </Link>
                        <Link
                            onClick={() => handleClick("/reach-us")}
                            className="header_content_item"
                        >
                            Reach us
                        </Link>
                        <Link
                            onClick={() => handleClick("/careers")}
                            className="header_content_item"
                        >
                            Careers
                        </Link>
                        <a
                            href="http://pthinks.com:2095/"
                            target="_blank"
                            rel="noreferrer"
                            className="header_content_item_btn_sub"
                        >
                            <img
                                className="header_content_item_btn_img"
                                src={user}
                                alt="User Login"
                            />
                            Login
                        </a>
                    </div>
                )}
            </header>
        </>
    );
}

export default Header;