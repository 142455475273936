// AnimatedTestimonialsDemo.js
import React from "react";
import AnimatedTestimonials from "./Testimonials";
import dheerendraProfile from "./images/team/dheerendra-profile.webp";
import bharathProfile from "./images/team/bharath-profile.webp";
import vijayProfile from "./images/team/vijay-profile.webp";
import gopalProfile from "./images/team/gopal-profile.webp";
import "./styles/Exeteam.css";

export default function AnimatedTestimonialsDemo() {
    const testimonials = [
        {
            src: dheerendraProfile,
            name: "Dheerendra V Purohit",
            designation: "Founder & CTO",
            description:
                "Founder of PthinkS and Qt Champion, has 25+ years of expertise in C++, Qt, and QML, leading a team of 100+ engineers. A BITS Pilani alumnus and patent holder, he collaborates with top firms like Mercedes Benz, Siemens, Baxter, NXP, etc.",
        },
        {
            src: gopalProfile,
            name: "Gopalkrishnan Lakshmi",
            designation: "Director of Growth & Strategy",
            description:
                "With 35+ years of experience, drives business expansion, strategic planning, and innovative technology solutions. Combines leadership expertise and global partnerships to foster innovation, ensuring sustainable growth and success.",
        },
        {
            src: vijayProfile,
            name: "Vijay Mangalpally",
            designation: "Senior Architect",
            description:
                "With over 25+ years of experience, specializes in designing and developing advanced solutions in C++, Qt, and QML. With a wealth of technical expertise, leads complex projects and collaborates with global clients to deliver cutting-edge software architectures.",
        },
        {
            src: bharathProfile,
            name: "Bharath Renukaswamy",
            designation: "Manager–Learning and Development",
            description:
                "With 17+ years of experience, excels in designing training programs, fostering leadership, and managing operations. His innovative approach drives organizational transformation and enhances workforce capabilities.",
        },
    ];

    return (
        <div className="animated-testimonials-demo">


            <AnimatedTestimonials testimonials={testimonials} />
        </div>
    );
}

