import React, { useState } from 'react';
import './styles/AnnouncementEdit.css';

export function UpdateAnnouncementForm() {
    const [formData, setFormData] = useState({
        trainingDate: '',
        trainingTime: '',
        venue: '',
    });
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            announcementData: {
                trainingDate: formData.trainingDate,
                trainingTime: formData.trainingTime,
                venue: formData.venue,
            },
        };

        try {
            const response = await fetch(
                "https://api.jsonbin.io/v3/b/677d3e0bacd3cb34a8c58606",
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "X-Master-Key": "$2a$10$g9HFN7jTfYswexcES.bBuOx0cARlZHwZ6eQHA6.JDZTbkAwi9CWXa",
                    },
                    body: JSON.stringify(payload),
                }
            );

            if (response.ok) {
                setMessage("Announcement data updated successfully!");
                setError(null);
            } else {
                const errorText = await response.text();
                console.error("Update failed:", errorText);
                setError(`Failed to update announcement: ${response.status}`);
                setMessage(null);
            }
        } catch (err) {
            console.error("Error during API call:", err);
            setError("An error occurred while updating the announcement.");
            setMessage(null);
        }
    };

    return (
        <div className="update-form-container">
            <h2>Update Announcement Details</h2>
            {message && <p className="success-message">{message}</p>}
            {error && <p className="error-message">{error}</p>}
            <form onSubmit={handleSubmit} className="update-form">
                <div className="form-group">
                    <label htmlFor="trainingDate">Training Date</label>
                    <input
                        type="text"
                        id="trainingDate"
                        name="trainingDate"
                        value={formData.trainingDate}
                        onChange={handleChange}
                        placeholder="Enter Training Date (e.g., March 10, 2025)"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="trainingTime">Training Time</label>
                    <input
                        type="text"
                        id="trainingTime"
                        name="trainingTime"
                        value={formData.trainingTime}
                        onChange={handleChange}
                        placeholder="Enter Training Time (e.g., 9:30 AM - 5:30 PM)"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="venue">Venue</label>
                    <input
                        type="text"
                        id="venue"
                        name="venue"
                        value={formData.venue}
                        onChange={handleChange}
                        placeholder="Enter Venue (e.g., PthinkS)"
                        required
                    />
                </div>
                <button type="submit" className="submit-btn">
                    Update Announcement
                </button>
            </form>
        </div>
    );
}