import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './styles/VisitorLogin.css';
import logo from '../components/images/header/pthinks-logo.webp'; // Import your logo image

const VisitorLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || '/visitor';

    const handleLogin = (e) => {
        e.preventDefault();
        if (email === 'PTS002' && password === 'bharath@123') {
            localStorage.setItem('isAuthenticated', 'true');
            navigate(from, { replace: true });
        } else {
            alert('Invalid credentials');
        }
    };

    return (
        <div className="login-container">
            <div className="login-card">
                <img src={logo} alt="Logo" className="login-logo" /> {/* Add the logo here */}
                <h2 className="login-title"></h2>
                <form className="login-form" onSubmit={handleLogin}>
                    <div className="form-group">
                        <label>Username :</label>
                        <input 
                            type="text" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            required 
                        />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input 
                            type="password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            required 
                        />
                    </div>
                    <button className="login-button" type="submit">
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
};

export default VisitorLogin;